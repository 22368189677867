import * as React from "react";

import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { Provider } from "jotai";
import { heroAtom, seoAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

const NotFoundPage = (): JSX.Element => {
  useHydrateAtoms([
    [heroAtom, undefined],
    [seoAtom, { title: "404: Not found" }],
  ])
  return (
    <>
      <Helmet>
        <title> 404 Content Missing | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <Title>Page Not Found</Title>
          </Header>
          <p className="min-h-[25vh]">
            We're Sorry! We can't find that page.
            <br />
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            <br />
            You can visit the <a href="/">EP3 homepage</a> or try <a href="/search">searching</a>.
          </p>
        </Container>
      </Layout>
    </>
  );
};

export default NotFoundPage;
