import * as React from "react";
import NotFound from "pages/404";
import "../styles/layout.css";
import "../styles/presentationDoc.css";
import { graphql } from "gatsby";

type PresentationDocumentTemplateProps = {
  data: {
    sanityPresentationDocument: {
      filename: string;
      file: any;
    };
  };
};

const PresentationDocumentTemplate = ({ data }: PresentationDocumentTemplateProps) => {
  const url = data.sanityPresentationDocument?.file._rawAsset.url;
  // const filename = data.sanityPresentationDocument.filename
  return url ? (
    <div id="pdf-wrapper" className="relative w-full min-w-screen h-full min-h-screen bg-gray-400">
      <embed id="pdf-viewer" width="100%" height="100%" src={url} type="application/pdf"></embed>
    </div>
  ) : (
    <NotFound />
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    sanityPresentationDocument(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      filename
      file {
        _type
        _rawAsset(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
export default PresentationDocumentTemplate;
